import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "me-4" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-flex btn-light btn-active-primary fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_6 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = { class: "card-body pt-0" }
const _hoisted_11 = { class: "text-capitalize" }
const _hoisted_12 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_13 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "svg-icon svg-icon-3" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_AuctionsFilter = _resolveComponent("AuctionsFilter")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'auctions'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
              ]),
              _createVNode(_component_AuctionsFilter, {
                onTypeSelected: _ctx.onTypeChange,
                onReset: _ctx.onReset
              }, null, 8, ["onTypeSelected", "onReset"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.can('create', 'auctions'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('auctions-creating')))
                  }, [
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewAuction')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-organiserName": _withCtx(({ row: auction }) => [
              _createTextVNode(_toDisplayString(auction.organiserName), 1)
            ]),
            "cell-eventDate": _withCtx(({ row: auction }) => [
              _createTextVNode(_toDisplayString(auction.eventDate), 1)
            ]),
            "cell-eventTime": _withCtx(({ row: auction }) => [
              _createTextVNode(_toDisplayString(auction.eventTime), 1)
            ]),
            "cell-venue": _withCtx(({ row: auction }) => [
              _createTextVNode(_toDisplayString(_ctx.locale.value === 'ar' ? auction.venueAr : auction.venueEn), 1)
            ]),
            "cell-slug": _withCtx(({ row: auction }) => [
              _createElementVNode("span", _hoisted_11, _toDisplayString(auction.slug), 1)
            ]),
            "cell-totalProperties": _withCtx(({ row: auction }) => [
              _createTextVNode(_toDisplayString(auction.auctionPropertysCount), 1)
            ]),
            "cell-is_active": _withCtx(({ row: auction }) => [
              (auction.is_active)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.translate('active')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.translate('inactive')), 1))
            ]),
            "cell-actions": _withCtx(({ row: auction }) => [
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                onClick: _withModifiers(($event: any) => (_ctx.editAuction(auction.id)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_15, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                ])
              ], 8, _hoisted_14),
              (_ctx.can('remove', 'auctions'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                    onClick: _withModifiers(($event: any) => (_ctx.toggleStatusAuction(auction)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_17, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen019.svg" })
                    ])
                  ], 8, _hoisted_16))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}